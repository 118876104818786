import {SET_TOPICANDTITLE} from '../actions/TopicTitleList';

const initialState = {
    topicTitleList: [] 
  };


  const topicAndTitleReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_TOPICANDTITLE:
        return {
          ...state,
          topicTitleList: action.data
        };
      default:
        return state;
    }
  };
  
  export default topicAndTitleReducer;