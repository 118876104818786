import thunk from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";
import RootReducer from "./reducers/RootReducer";
import { persistReducer } from "redux-persist";
import autoMergeLevel2  from  "redux-persist/lib/stateReconciler/autoMergeLevel2";
import sessionStorage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'recipe-app',
  storage: sessionStorage,
  stateReconciler: autoMergeLevel2
}

const persistedReducer = persistReducer(persistConfig, RootReducer)

const initialState = {};
const middleware = [thunk];
const middlewareEnhancer = applyMiddleware(...middleware)
const composedEnhancers = compose(
    middlewareEnhancer,
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

export const Store = createStore(persistedReducer, composedEnhancers);
// const middlewares = [thunk];
/*let devtools = x => x;

if (
  process.env.NODE_ENV !== "production" &&
  process.browser &&
  window.__REDUX_DEVTOOLS_EXTENSION__
) {
  devtools = window.__REDUX_DEVTOOLS_EXTENSION__();
}

export const Store = createStore(
  RootReducer,
  initialState,
  compose(applyMiddleware(...middlewares), devtools)
);*/
