import React, { Fragment, useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppContext from "app/appContext";
import { apiUrl } from "utils/config";

const redirectRoute = props => {
  const { location, history } = props;
  const { pathname } = location;
  console.log('Redirecting to login, but not really');
  window.location.href = apiUrl+'/login';
  /*history.push({
    pathname: "/session/signin",
    state: { redirectUrl: pathname }
  });*/
};

const getAuthStatus = (props, routes) => {
  // console.log('getAuthStatus props', props, 'routes ', routes);
  const { location, user } = props;
  const { pathname } = location;
  const matched = routes.find(r => r.path === pathname);
  window.xtprops = props;
  window.xtroutes = routes;
  window.xtlocation = location;
  window.xtuser = user;
  window.xtmatched = matched;
  const authenticated =
    matched && matched.auth && matched.auth.length
      ? matched.auth.includes(user.role)
      : true;
  console.log('getAuthStatus Authenticated ', authenticated, 'Matched ', matched, 'User ', user);
  window.xtauthenticated = authenticated;
  return authenticated;
};

const AuthGuard = ({ children, ...props }) => {
  const { routes } = useContext(AppContext);


  let [authenticated, setAuthenticated] = useState(
    getAuthStatus(props, routes)
  );
  console.log('AuthGuard ', authenticated, props.user);

  useEffect(() => {
    if (!authenticated) {
      redirectRoute(props);
    }
    setAuthenticated(getAuthStatus(props, routes));
  }, [setAuthenticated, authenticated, routes, props]);

  return authenticated ? <Fragment>{children}</Fragment> : null;
};

const mapStateToProps = state => ({
  user: state.user
});

export default withRouter(connect(mapStateToProps)(AuthGuard));
