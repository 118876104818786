// export const apiUrl = "http://localhost:3001";
const localPath = `http://localhost:3001`;
const devPath = `http://localhost:3001`;
const stagePath = `https://stage.checklist-backend.hebidevelop.com`;
const prodPath = `https://checklist-backend.hebidevelop.com`;
const app_env = 'stage'; // process.env.NODE_ENV; // development, stage, production
let apiUrl = localPath;
switch (app_env) {
  case 'production':
    apiUrl = prodPath;
    break;
  case 'stage':
    apiUrl = stagePath;
    break;
  case 'development':
    apiUrl = devPath;
    break;
  default:
    apiUrl = localPath;
    break;
}
export { apiUrl, app_env };
