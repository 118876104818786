import {SET_TITLE} from '../actions/titleType';

const initialState = {
    title: "Dashboard"
  };


  const TitleTypeReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_TITLE:
        return {
          ...state,
          title: action.title
        };
      default:
        return state;
    }
  };
  
  export default TitleTypeReducer;