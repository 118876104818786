import React from "react";

const topicRoutes = [
  {
    path: "/topic/createtopic",
    component: React.lazy(() => import("./CreateTopic"))
  }
//   {
//     path: "/forms/editor",
//     component: React.lazy(() => import("./EditorForm"))
//   },
//   {
//     path: "/forms/upload",
//     component: React.lazy(() => import("./UploadForm"))
//   },
//   {
//     path: "/forms/wizard",
//     component: React.lazy(() => import("./WizardForm"))
//   }
];

export default topicRoutes;
