import { combineReducers } from "redux";
import LoginReducer from "./LoginReducer";
import UserReducer from "./UserReducer";
import LayoutReducer from "./LayoutReducer";
import ScrumBoardReducer from "./ScrumBoardReducer";
import NotificationReducer from "./NotificationReducer";
import EcommerceReducer from "./EcommerceReducer";
import NavigationReducer from "./NavigationReducer";
import TitleTypeReducer from "./titleTypeReducer";
import TopicTitleReducer from "./TopicTitleReducer";
import EditTopicTitleReducer from "./EditTopicTitleReducer";

const RootReducer = combineReducers({
  login: LoginReducer,
  user: UserReducer,
  layout: LayoutReducer,
  scrumboard: ScrumBoardReducer,
  notifications: NotificationReducer,
  ecommerce: EcommerceReducer,
  navigations: NavigationReducer,
  titleType: TitleTypeReducer,
  topicAndTask: TopicTitleReducer,
  editTaskOrTopic: EditTopicTitleReducer
});

export default RootReducer;
