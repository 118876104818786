import {EDIT_TOPICANDTITLE, RESET_TOPICANDTITLE} from '../actions/EditTopicAndTitle';

const initialState = {
    editTopicOrTitle: null
  };


  const editTopicAndTitleReducer = (state = initialState, action) => {
    switch (action.type) {
      case EDIT_TOPICANDTITLE:
        return {
          ...state,
          editTopicOrTitle: action.data
        };
    case RESET_TOPICANDTITLE:
        return initialState;
      default:
        return state;
    }
  };
  
  export default editTopicAndTitleReducer;