export const EDIT_TOPICANDTITLE = "EDIT_TOPICANDTITLE";
export const RESET_TOPICANDTITLE = "RESET_TOPICANDTITLE";

export const setEditTopicOrTitle = data => dispatch => {
    dispatch({
      type: EDIT_TOPICANDTITLE,
      data: data
    });
  };

  export const resetEditTopicOrTitle = () => dispatch => {
    console.log("reset");
    dispatch({
      type: RESET_TOPICANDTITLE
    });
  };