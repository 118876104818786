import { SET_USER, LOGOUT_USER } from '../actions/User';

const initialState = {};


  const userReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_USER:
        return {
          ...state,
          ...action.data
        };
      case LOGOUT_USER:
        {
          return initialState;
        }
      default:
        return state;
    }
  };
  
  export default userReducer;