import React, { Component } from "react";
import {
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Button,
  CircularProgress
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import { loginWithEmailAndPassword } from "../../redux/actions/LoginActions";
import { apiUrl } from "utils/config";
import { setUser } from "app/redux/actions/User.js";
import ProfileImage from "../../Images/logo.png";

const styles = theme => ({
  wrapper: {
    position: "relative"
  },

  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
});

class SignIn extends Component {
  state = {
    email: "",
    password: "",
    agreement: "",
    token: null,
    loginMessage: ""
  };
  componentDidMount() {
    let path = window.location.href.split('token=');
    console.log('Path ', path);
    if(path[1]){
      console.log('Setting state ', path[1])
      this.setState({
        token: path[1]
      }, ()=>{
        let token = path[1];
        this.getProfile(token);
      })
    }else {
      window.location.href = apiUrl+'/login';
    }
  }
  getProfile = (token) => {
    fetch(`${apiUrl}/profile`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        return res.json();
      })
      .then(resData => {
        console.log('Profile response ', resData);
        if(resData.userrole === "lms_content_administrator" || resData.userrole === "administrator") {
          this.setState({
            loginMessage: "Login successfully done and redirected to iDevelop"
          },()=>{
            let user = {
              ...resData,
              token: token,
              role: 'ADMIN'
            };
            console.log('Logged in ', user);
            this.props.setUserData(user);
            this.props.history.push("/");
          })
        }else {
          this.setState({
            loginMessage: "You don't have permission to access this site"
          })
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  handleChange = event => {
    event.persist();
    this.setState({
      [event.target.name]: event.target.value
    });
  };
  
  handleFormSubmit = event => {
    const {email, password} = this.state;
    // this.props.loginWithEmailAndPassword({ ...this.state });
    var query = `mutation LoginUser($email: String!, $password: String) {
      loginUser(email: $email, password: $password) {
        success
        message
        token
      }
    }`;
    fetch(`${apiUrl}/graphql`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify({
        query,
        variables: {
          email: email,
          password: password
        }
      })
    }).then(res => {
      return res.json();
    }).then(response => {
      if(!response.errors) {
        let user = response.data.loginUser
        // this.props.setUserData(user);
        this.getProfile(user.token);
        // this.props.history.push("/")
      }
    })
  };
  render() {
    let { email, password } = this.state;
    let { classes, user } = this.props;
    return (
      <div className="signup flex justify-center w-full h-full-screen">
        <div className="p-8">
          <Card className="signup-card position-relative y-center">
            <Grid container>
              <Grid item lg={5} md={5} sm={5} xs={12}>
                <div className="p-8 flex justify-center items-center h-full">
                  <img src={ProfileImage} alt="" />
                </div>
              </Grid>
              <Grid item lg={7} md={7} sm={7} xs={12}>
                <div style={{marginTop: "75px"}} className="successMessage">
                {this.state.loginMessage}
                </div>
              </Grid>
              {/* <Grid item lg={7} md={7} sm={7} xs={12}>
                <div className="p-9 h-full bg-light-gray position-relative">
                  <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
                    <TextValidator
                      className="mb-6 w-full"
                      variant="outlined"
                      label="Email"
                      onChange={this.handleChange}
                      type="email"
                      name="email"
                      value={email}
                      validators={["required"]}
                      errorMessages={[
                        "this field is required"
                      ]}
                    />
                    <TextValidator
                      className="mb-3 w-full"
                      label="Password"
                      variant="outlined"
                      onChange={this.handleChange}
                      name="password"
                      type="password"
                      value={password}
                      validators={["required"]}
                      errorMessages={["this field is required"]}
                    />
                    <div className="flex flex-wrap items-center mb-4">
                      <div className={classes.wrapper}>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={this.props.login.loading}
                          type="submit"
                        >
                          Sign in to Enter Dashboard
                        </Button>
                        {this.props.login.loading && (
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                          />
                        )}
                      </div>
                      <span className="mr-2 ml-5">or</span>
                      <Button
                        className="capitalize btnBlue"
                      >
                        <a href={`${apiUrl}/login`}>SSO Login</a>
                      </Button>
                    </div>
                  </ValidatorForm>
                </div>
              </Grid> */}
            </Grid>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loginWithEmailAndPassword: PropTypes.func.isRequired,
  login: state.login,
  user: state.user
});
const mapDispatchToProps = dispatch => {
  return {
    setUserData: (data) => {
      dispatch(setUser(data));
    }
  }
}
export default withStyles(styles, { withTheme: true })(
  // withRouter(connect(mapStateToProps, { loginWithEmailAndPassword })(SignIn))
  withRouter(connect(mapStateToProps, mapDispatchToProps)(SignIn))
);
