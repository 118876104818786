import { authRoles } from "./auth/authRoles";

export const navigations = [
  // {
    // name: "Dashboard",
    // path: "/dashboard/analytics",
    // icon: "dashboard",
    // auth: authRoles.admin
  // },
  {
    name: "Checklist",
    icon: "list",
    children: [
      {
        name: "Add New",
        path: "/checklist/createchecklist",
        icon: "add"
      },
      {
        name: "View All",
        path: "/checklist/listchecklist",
        icon: "list"
      },
      /*{
        name: "Add New Checklist",
        path: "/checklist/createchecklist",
        icon: "assignment"
      },*/
      // {
      //   name: "Points",
      //   path: "/material/checkbox",
      //   iconText: "C"
      // },
    ]
  },
  {
    name: "Task",
    icon: "list",
    children: [
      {
        name: "Add New",
        path: "/task/create",
        icon: "add"
      },
      {
        name: "View All",
        path: "/tasks",
        icon: "list"
      }
    ]
  },
  /*{
    name: "Task",
    path: "/tasks",
    icon: "list",
    // auth: authRoles.admin
  },*/
  // {
  //   name: "Forms",
  //   icon: "description",
  //   children: [
  //     {
  //       name: "Basic",
  //       path: "/forms/basic",
  //       iconText: "B"
  //     },
  //     {
  //       name: "Editor",
  //       path: "/forms/editor",
  //       iconText: "E"
  //     },
  //     {
  //       name: "Upload",
  //       path: "/forms/upload",
  //       iconText: "U",
  //       badge: { value: "Pro", color: "secondary" }
  //     },
  //     {
  //       name: "Wizard",
  //       path: "/forms/wizard",
  //       iconText: "W",
  //       badge: { value: "Pro", color: "secondary" }
  //     }
  //   ]
  // },
  // {
  //   name: "Multilevel",
  //   icon: "dynamic_feed",
  //   children: [
  //     {
  //       name: "Level 1",
  //       iconText: "L1",
  //       children: [
  //         {
  //           name: "Level 2",
  //           path: "/charts/victory-charts",
  //           iconText: "L2"
  //         },
  //         {
  //           name: "Level 2",
  //           path: "/charts/react-vis",
  //           iconText: "L2"
  //         },
  //         {
  //           name: "Level 2",
  //           path: "/charts/recharts",
  //           iconText: "L2"
  //         },
  //         {
  //           name: "Level 2",
  //           path: "/charts/echarts",
  //           iconText: "L2"
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   name: "Utilities",
  //   icon: "format_list_bulleted",
  //   children: [
  //     {
  //       name: "Color",
  //       path: "/utilities/color",
  //       iconText: "C",
  //       auth: authRoles.admin
  //     },
  //     {
  //       name: "Spacing",
  //       path: "/utilities/spacing",
  //       iconText: "S",
  //       auth: authRoles.admin
  //     },
  //     {
  //       name: "Typography",
  //       path: "/utilities/typography",
  //       iconText: "T"
  //     },
  //     {
  //       name: "Display",
  //       path: "/utilities/display",
  //       iconText: "D"
  //     },
  //     {
  //       name: "Position",
  //       path: "/utilities/position",
  //       iconText: "P"
  //     },
  //     {
  //       name: "Shadow",
  //       path: "/utilities/shadow",
  //       iconText: "S"
  //     }
  //   ]
  // },
  // {
  //   name: "Sessions",
  //   icon: "trending_up",
  //   children: [
  //     {
  //       name: "Sign in",
  //       iconText: "SI",
  //       path: "/session/signin"
  //     },
  //     {
  //       name: "Sign up",
  //       iconText: "SU",
  //       path: "/session/signup"
  //     },
  //     {
  //       name: "Forgot Password",
  //       iconText: "FP",
  //       path: "/session/forgot-password"
  //     },
  //     {
  //       name: "Error",
  //       iconText: "404",
  //       path: "/session/404"
  //     }
  //   ]
  // },
  // {
  //   name: "Drag and Drop",
  //   icon: "pan_tool",
  //   path: "/others/drag-and-drop"
  // },
  // {
  //   name: "CRUD Table",
  //   icon: "format_list_bulleted",
  //   path: "/crud-table",
  //   badge: { value: "Pro", color: "secondary" }
  // },
  // {
  //   name: "Ecommerce",
  //   icon: "shopping_basket",
  //   badge: { value: "Pro", color: "secondary" },
  //   children: [
  //     {
  //       name: "Shop",
  //       path: "/ecommerce/shop",
  //       iconText: "S"
  //     },
  //     {
  //       name: "Cart",
  //       path: "/ecommerce/cart",
  //       iconText: "C"
  //     },
  //     {
  //       name: "Checkout",
  //       path: "/ecommerce/checkout",
  //       iconText: "CO"
  //     }
  //   ]
  // },
  // {
  //   name: "Scrum Board",
  //   icon: "group_work",
  //   path: "/scrum-board",
  //   badge: { value: "Pro", color: "secondary" }
  // },
  // {
  //   name: "Matx List",
  //   icon: "list",
  //   badge: { value: "Pro", color: "secondary" },
  //   children: [
  //     {
  //       name: "Infinite Scroll",
  //       path: "/infinite-scroll",
  //       iconText: "I"
  //     },
  //     {
  //       name: "List",
  //       path: "/matx-list",
  //       iconText: "L"
  //     }
  //   ]
  // },
  // {
  //   name: "Invoice Builder",
  //   icon: "receipt",
  //   path: "/invoice/list",
  //   badge: { value: "Pro", color: "secondary" }
  // },
  // {
  //   name: "Calendar",
  //   icon: "date_range",
  //   path: "/calendar",
  //   badge: { value: "Pro", color: "secondary" }
  // },
  // {
  //   name: "Chat",
  //   icon: "chat",
  //   path: "/chat",
  //   badge: { value: "Pro", color: "secondary" }
  // },
  // {
  //   name: "Inbox",
  //   icon: "inbox",
  //   path: "/inbox",
  //   badge: { value: "Pro", color: "secondary" }
  // },
  // {
  //   name: "Todo",
  //   icon: "center_focus_strong",
  //   path: "/todo/list",
  //   badge: { value: "Pro", color: "secondary" }
  // },

  // {
  //   name: "Pages",
  //   icon: "view_carousel",
  //   badge: { value: "Pro", color: "secondary" },
  //   children: [
  //     {
  //       name: "Pricing",
  //       icon: "blur_on",
  //       path: "/others/pricing",
  //       badge: { value: "Pro", color: "secondary" }
  //     },
  //     {
  //       name: "Left Sidebar Card",
  //       path: "/page-layouts/Left-sidebar-card",
  //       iconText: "L"
  //     }

  //     // {
  //     //   name: "User Profile",
  //     //   path: "/page-layouts/user-profile",
  //     //   iconText: "U"
  //     // }
  //   ]
  // },
  // {
  //   name: "Charts",
  //   icon: "trending_up",
  //   badge: { value: "Pro", color: "secondary" },
  //   children: [
  //     {
  //       name: "Echarts",
  //       path: "/charts/echarts",
  //       iconText: "E"
  //     },
  //     // {
  //     //   name: "Recharts",
  //     //   path: "/charts/recharts",
  //     //   iconText: "R"
  //     // },
  //     {
  //       name: "React Vis",
  //       path: "/charts/react-vis",
  //       iconText: "R"
  //     },
  //     {
  //       name: "Victory Chart",
  //       path: "/charts/victory-charts",
  //       iconText: "V"
  //     }
  //   ]
  // },
  // {
  //   name: "Map",
  //   icon: "add_location",
  //   path: "/map"
  // },
  // {
  //   name: "Documentation",
  //   icon: "launch",
  //   type: "extLink",
  //   path: "http://demos.ui-lib.com/matx-react-doc/"
  // }
];
