import React from "react";
import { authRoles } from "../../auth/authRoles";

const checklistRoutes = [
  {
    path: "/checklist/createchecklist/:id",
    exact: true,
    component: React.lazy(() => import("./CreateChecklist")),
    auth: authRoles.admin
  },
  {
    path: "/checklist/createchecklist",
    exact: true,
    component: React.lazy(() => import("./CreateChecklist")),
    auth: authRoles.admin
  },
  {
    path: "/checklist/listchecklist",
    exact: true,
    component: React.lazy(() => import("./ListChecklist")),
    auth: authRoles.admin
  },
  {
    path: "/checklist/topic/create/:checklistid",
    exact: true,
    component: React.lazy(() => import("./topic/CreateTopic")),
    auth: authRoles.admin
  },
  {
    path: "/checklist/topic/update/:checklistid/:id",
    exact: true,
    component: React.lazy(() => import("./topic/CreateTopic")),
    auth: authRoles.admin
  },
  {
    path: "/checklist/topics/:checklistid",
    exact: true,
    component: React.lazy(() => import("./topic/TopicList")),
    auth: authRoles.admin
  },
  {
    path: "/tasks",
    exact: true,
    component: React.lazy(() => import("../tasks/TaskList")),
    auth: authRoles.admin
  },
  {
    path: "/task/create",
    exact: true,
    component: React.lazy(() => import("../tasks/CreateTask")),
    auth: authRoles.admin
  }
];

export default checklistRoutes;