export const SET_USER = "SET_USER";
export const LOGOUT_USER = "LOGOUT_USER";

export const setUser = data => dispatch => {
    dispatch({
      type: SET_USER,
      data: data
    });
  };

  export const logoutUser = () => dispatch => {
    dispatch({
      type: LOGOUT_USER
    });
  };